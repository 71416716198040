<template>
  <!-- 顶部搜索栏 -->
  <div class="search">
    <el-row>
      <el-col :span="24">
        <div class="search-form">
          <el-row type="flex" align="middle" :gutter="10">
            <el-col :span="4">
              <el-input v-model.trim="inputName" clearable>
                <template slot="prepend">姓名</template>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-input v-model="inputMobile" clearable>
                <template slot="prepend">手机号</template>
              </el-input>
            </el-col>
            <el-col :span="3" v-if="showExam">
              <el-select v-model="examRes" placeholder="审核结果">
                <el-option
                  v-for="item in examOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="3">
              <el-select v-model="channel" placeholder="渠道">
                <el-option
                  v-for="item in channelOpts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="3">
              <el-select v-model="role" placeholder="客服">
                <el-option
                  v-for="item in roleOpts"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
            
            <el-button class="hidden-xs-only" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
            
          </el-row>
        </div>
        <el-button class="bot hidden-sm-and-up" type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from '../api';
export default {
  props: {
    showExam: {
      type: Boolean,
      default: false
    },
    examRes:{
      type: Number,
      default: null
    }
  },
  data() {
    return {
      inputName: '', // 输入的姓名
      inputMobile: '', // 输入的手机号
      // examRes: null, // 选择的审查结果
      channel: null, // 选择的渠道
      role: null, // 选择的客服
      examOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '未处理' },
        { value: 1, label: '通过' },
        { value: 2, label: '拒绝' },
        { value: -1, label: '黑名单' }
      ],
      channelOpts: [],
      roleOpts: []
    };
  },
  methods: {
    // 获取客服选项
    getCustomers() {
      return service.getCustomerList().then(res => {
        res = res.filter(item => !!item);
        res.splice(0, 0, { id: '', username: '请选择' });
        this.roleOpts = res;
      });
    },
    // 获取渠道选项
    getChannelList() {
      return service.getChannels().then(res => {
        res = res.filter(item => !!item);
        res.splice(0, 0, { id: '', name: '请选择' });
        this.channelOpts = res || [];
      });
    },
    search() {
      let params = {
        username: this.inputName,
        mobile: this.inputMobile,
        status: this.examRes,
        channelId: this.channel,
        manageId: this.role
      };
      this.$emit('search', params);
    }
  },
  mounted() {
    this.getCustomers();
    this.getChannelList();
  }
};
</script>

<style lang="scss" scoped>
.search {
  &-form {
    overflow: hidden;
    text-align: left;
    padding: 10px 0 15px 0;
    // border-bottom: 1px solid #eee;
  }
}
@media screen and (max-width: 480px) {
  .search{
    height: 12rem;
    .search-form{
      height: 100%;
      .el-row--flex{
        display: block!important;
        .el-col-4{
          width: 100%;
        }
        .el-col-6{
          width: 100%;
          margin-top: 1rem;
        }
        .el-col-3{
          margin-top: 1rem;
          width: 50%;
        }
       
      }
    }
     .bot{
          margin-bottom: 2rem;
          width: 8rem;
          float: left;
        }
  }
  
}
</style>
